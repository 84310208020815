
import CloudFun, {
  computed,
  Condition,
  defineComponent,
  Operator,
  reactive,
  ref,
  onMounted,
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import XEUtils from "xe-utils";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import { watch } from "vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const router = CloudFun.current?.policy?.router;
    const grid = ref<any>({});
    const itemsGrid = ref<any>({});
    const insuranceGrid = ref<any>({});
    const query = reactive({
      startTime: '',
      endTime: '',
      store: 0,
    })

    const gridOptions: GridOptions = {
      stripe: false,
      title: "訂單",
      canCreate: false,
      canUpdate: false,
      canRead: false,
      canDelete: false,
      multiselect: true,
      showFooter: true,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      exportConfig: {
        filename: "佣金紀錄",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: [
          { field: 'Time' },
          { field: 'Store.Name' },
          { field: 'Order.Name' },
          { field: 'ItemCount' },
          { field: 'TimeDiff' },
          { field: 'Order.Amount' },
          { field: 'Amount' },
          { field: 'Base' },
        ]
      },
      columns: [
        {
          field: "Time",
          title: "時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: 150,
          formatter: ({ cellValue }) =>
            CloudFun.utils.formatDate(cellValue, "yyyy/MM/dd HH:mm")
        },
        {
          field: "Store.Name",
          title: "合作店家",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Order.Name",
          title: "購買人姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ItemCount",
          title: "車數",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "TimeDiff",
          title: "總購買時數",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Order.Amount",
          title: "訂單金額",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Amount",
          title: "佣金金額",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Base",
          title: "佣金比例(%)",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Paid",
          title: "已撥款",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter({ cellValue }) { return cellValue ? "是" : "否" }
        }
      ],
      promises: {
        query: model
          ? (params) => {
            if (!params.sortings) params.sortings = [];
            params.sortings.push({ column: "Time", order: 1 });
            params.condition = params.condition || new Condition();
            if (query.store) params.condition.and("StoreId", Operator.Equal, query.store);
            if (query.startTime) params.condition.and("Time", Operator.GreaterThanOrEqual, query.startTime);
            if (query.endTime) params.condition.and("Time", Operator.LessThan, query.endTime);
            return new Promise((resolve) => {
              model.dispatch('commissionRecord/query', params).then(res => {
                res.data.forEach((item: any) => {
                  item.ItemCount = item.Order.Items.filter((e: any) => e.ProductId != null).length;
                  const end = new Date(item.Order.EndTime).getTime();
                  const start = new Date(item.Order.StartTime).getTime();
                  item.TimeDiff = ((end - start) / 3600000).toFixed(2);
                })
                resolve(res);
              })
            });
          }
          : undefined,
        queryAll: model ? () => {
          const params = { sortings: [{ column: "Time", order: 1 }], condition: new Condition() }
          if (query.store) params.condition.and("StoreId", Operator.Equal, query.store);
          if (query.startTime) params.condition.and("Time", Operator.GreaterThanOrEqual, query.startTime);
          if (query.endTime) params.condition.and("Time", Operator.LessThan, query.endTime);
          return new Promise((resolve) => {
            model.dispatch('commissionRecord/query', params).then(res => {
              res.forEach((item: any) => {
                item.ItemCount = item.Order.Items.filter((e: any) => e.ProductId != null).length;
                const end = new Date(item.Order.EndTime).getTime();
                const start = new Date(item.Order.StartTime).getTime();
                item.TimeDiff = ((end - start) / 3600000).toFixed(2);
              })
              resolve(res);
            })
          });
        } : undefined,
        save: model ? params => model.dispatch("commissionRecord/save", params) : undefined
      },
      modalConfig: { showFooter: true, width: "80%", height: "80%" },
      //   treeConfig: { children: "Children" },
      footerMethod({ columns, data }) {
        return [
          columns.map((column, columnIndex) => {
            if (columnIndex === 1) {
              return "總計";
            }
            if (columnIndex === 7) {
              return XEUtils.commafy(XEUtils.sum(data.map((row) => row.Amount)));
            }
          }),
        ] as any;
      }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: "right",
      // size: "medium",
      items: [
        {
          field: "Name",
          title: "聯絡人",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入聯絡人", clearable: true },
          }
        },
        {
          field: "Phone",
          title: "連絡電話",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入連絡電話" }
          }
        },
        // {
        //   field: "AlternatePhone",
        //   title: "備用連絡電話",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { placeholder: "請輸入備用連絡電話" }
        //   }
        // },
        // {
        //   field: "Email",
        //   title: "Email",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { placeholder: "請輸入聯絡Email" }
        //   }
        // },
        {
          field: "Status",
          title: "狀態",
          span: 12,
          itemRender: {
            name: "$select",
            options: model
              ? Object.values(model.enums.OrderStatus).map(e => {
                return { label: e.Name, value: e.Value };
              })
              : [],
            props: { type: "text", placeholder: "請選擇狀態" }
          }
        },
        {
          field: "StoreId",
          title: "預約店家",
          span: 12,
          slots: { default: "column-store-id" }
        },
        {
          field: "StationId",
          title: "取車點",
          span: 12,
          slots: { default: "column-station-id" }
        },
        {
          field: "ReturnStationId",
          title: "歸還點",
          span: 12,
          slots: { default: "column-return-station-id" }
        },
        {
          field: "BookingTime",
          title: "預約取車時間",
          span: 12,
          slots: { default: "column-booking-time" },
        },
        {
          field: "BookingExpiryTime",
          title: "預約保留期限",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", disabled: true }
          }
        },
        {
          field: "StartTime",
          title: "取車時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", disabled: true }
          }
        },
        {
          field: "EndTime",
          title: "還車時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", disabled: true }
          }
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: { type: "text", placeholder: "請輸入負責人姓名" }
          }
        },
        {
          field: "ShippingAmount",
          title: "甲乙地費用",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", disabled: true }
          }
        },
        {
          field: "Amount",
          title: "總金額",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", disabled: true }
          }
        },
      ],
      rules: {
        Name: [{ required: true }],
        // Email: [{
        //   required: false,
        //   validator: (params) => {
        //     if (params.itemValue && CloudFun.utils.validator.validateEmail(params.itemValue)) return new Error("Email格式錯誤");
        //   }
        // }],
        Phone: [{
          required: true,
          validator: (params) => {
            if (!params.itemValue || CloudFun.utils.validator.validatePhoneNumber(params.itemValue)) return new Error("手機格式錯誤");
          }
        }],
        // AlternatePhone: [{
        //   required: false,
        //   validator: (params) => {
        //     if (params.itemValue && CloudFun.utils.validator.validatePhoneNumber(params.itemValue)) return new Error("手機格式錯誤");
        //   }
        // }],
        StationId: [{ required: true }],
        ReturnStationId: [{ required: true }],
        BookingTime: [{ required: true }],
        // StoreId: [{ required: true }],
      }
    };

    const itemsGridOptions: GridOptions = {
      mode: "inline",
      multiselect: false,
      autoResize: true,
      columns: [
        {
          field: "ProductId", title: "產品", sortable: true,
          slots: { default: "column-product-id" },
        },
        {
          field: "Price", title: "價格", sortable: true, width: "100", align: "right", resizable: true, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue),
          editRender: {
            name: '$input',
            immediate: true,
            props: { type: 'number', min: 0 },
            events: {
              input: (params, event) => { params.row.Price = event.value; params.row.Amount = params.row.Price * params.row.Quantity; },
            }
          }
        },
        {
          field: "Quantity", title: "時數/數量", sortable: true, width: "120", align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue),
          editRender: {
            name: '$input',
            immediate: true,
            props: { type: 'number', min: 1 },
            events: {
              input: (params, event) => { params.row.Quantity = event.value; params.row.Amount = params.row.Price * params.row.Quantity; },
            }
          }
        },
        {
          field: "Amount", title: "金額", sortable: true, width: "100", headerAlign: "left", align: "right", resizable: true, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue),
        },
      ],
      promises: {
        query: model
          ? params => {
            params.condition = new Condition(
              "OrderId",
              Operator.Equal,
              grid.value.editingRow?.Id || 0
            ).and(params.condition!);
            return model.dispatch("orderItem/query", params);
          }
          : undefined, // eslint-disable-line
        queryAll: model ? () => model.dispatch("orderItem/query") : undefined,
        save: model
          ? (params) => model.dispatch("orderItem/save", params).then(async () => { grid.value.editingRow.Amount = (await model.dispatch('commissionRecord/find', grid.value.editingRow.Id)).Amount; grid.value.refresh(); })
          : undefined
      }
    };

    const insuranceGridOptions: GridOptions = {
      mode: "inline",
      multiselect: false,
      autoResize: true,
      columns: [
        {
          field: "PersonalId",
          title: "身份證字號",
          showOverflow: true,
          sortable: true,
          editRender: { name: "$input", immediate: true }
        },
        {
          field: "BirthDate",
          title: "生日",
          showOverflow: true,
          sortable: true,
          editRender: { name: "$input", props: { type: "date" }, immediate: true },
          formatter: ({ cellValue }) => CloudFun.utils.formatDate(cellValue, "yyyy/MM/dd")
        },
        {
          field: "Name",
          title: "姓名",
          showOverflow: true,
          sortable: true,
          editRender: { name: "$input", immediate: true }
        },
        {
          field: "MobilePhone",
          title: "手機",
          showOverflow: true,
          sortable: true,
          editRender: { name: "$input", immediate: true },
          formatter: ({ cellValue }) => CloudFun.utils.formatPhone(cellValue)
        }
      ],
      editRules: {
        PersonalId: [{ required: true, message: "未輸入身分證字號" }],
        Name: [{ required: true, message: "未輸入姓名" }],
        BirthDate: [{ required: true, message: "未選擇生日" }],
        MobilePhone: [{
          required: true,
          message: "未輸入手機",
          validator: (params) => {
            if (!params.cellValue || CloudFun.utils.validator.validatePhoneNumber(params.cellValue)) return new Error("手機格式錯誤");
          }
        }]
      },
      promises: {
        query: model
          ? (params) => {
            params.condition = new Condition(
              "OrderId",
              Operator.Equal,
              grid.value.editingRow?.Id || 0
            ).and(params.condition!);
            return model.dispatch('insuranceRecord/query', params)
          }
          : undefined, // eslint-disable-line
        save: model
          ? params => model.dispatch("insuranceRecord/save", params)
          : undefined
      }
    };

    const stationIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      transfer: true,
      rowId: "Id",
      placeholder: "選擇站點",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("station/find", value), // eslint-disable-line
        query: params => model!.dispatch("station/query", params) // eslint-disable-line
      }
    };

    const storeIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      transfer: true,
      rowId: "Id",
      placeholder: "選擇店家",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("store/find", value), // eslint-disable-line
        query: params => model!.dispatch("store/query", params) // eslint-disable-line
      }
    };

    const productIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      transfer: true,
      rowId: "Id",
      placeholder: "選擇產品",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Number",
          title: "編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        },
        {
          field: "SalePrice",
          title: "售價",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("product/find", value), // eslint-disable-line
        query: params => model!.dispatch("product/query", params) // eslint-disable-line
      }
    };

    const monthRange = ref<any>(null);

    const queryDatepicker = ref({} as any);
    const formDatepicker = ref({} as any);
    const bookingTimePickerOptions = {
      locale: "zh-TW",
      minDate: null,
      format: "yyyy/MM",
      hideInputIcon: true,
      clearable: false,
      textInput: true,
      monthPicker: true,
      // selectText: "確認",
      // cancelText: "取消"
    };

    // [hour, minute]
    let areaStartTime: number[] = [];
    let areaEndTime: number[] = [];
    let areaHolidayStartTime: number[] = [];
    let areaHolidayEndTime: number[] = [];
    const changeStation = async (id: number, row: any, clearBookingTime = true) => {
      try {
        if (!id) {
          return;
        }
        row.StationId = id;
        const temp = await model?.dispatch("station/find", row.StationId);
        areaStartTime = temp.Area.StartTime.split(":").map((e: string) => parseInt(e));
        areaEndTime = temp.Area.EndTime.split(":").map((e: string) => parseInt(e));
        areaHolidayStartTime = temp.Area.HolidayStartTime?.split(":").map((e: string) => parseInt(e));
        areaHolidayEndTime = temp.Area.HolidayEndTime?.split(":").map((e: string) => parseInt(e));
        if (clearBookingTime) {
          row.BookingTime = null;
        }
      } catch (e: any) {
        CloudFun.send('error', e);
      }
    }

    const selectBookingTime = async (value: Date) => {
      const now = value?.getTime();
      // if (!now || new Date().getTime() > now) {
      //   CloudFun.send("error", "請選擇未來時間");
      //   return;
      // }

      const y = value.getFullYear();
      const m = value.getMonth();
      const d = value.getDate();
      const isHoliday = await model?.dispatch("calendar/isHoliday", `${y}/${m + 1}/${d}`);
      const hour = isHoliday ? areaHolidayStartTime[0] : areaStartTime[0];
      const minute = isHoliday ? areaHolidayStartTime[1] : areaStartTime[1];
      const endHour = isHoliday ? areaHolidayEndTime[0] : areaEndTime[0];
      const endMinute = isHoliday ? areaHolidayEndTime[1] : areaEndTime[1];
      const startTime = new Date(y, m, d, hour, minute, 0, 0).getTime();
      const endTime = new Date(y, m, d, endHour, endMinute, 0, 0).getTime();
      if (now < startTime || now > endTime) {
        CloudFun.send("error", `請選擇營業時間${hour}:${minute.toString().padStart(2, '0')} - ${endHour}:${endMinute.toString().padStart(2, '0')}`);
        return;
      }
      formDatepicker.value.selectDate();
    }

    onMounted(() => {
      const orderNumber = window.localStorage.getItem("OrderNumber");
      window.localStorage.removeItem("OrderNumber");
      if (orderNumber) {
        if (grid.value) {
          grid.value.keyword = orderNumber
        }
      }

      const q = router?.currentRoute.value.query;
      if (q?.startTime) query.startTime = q.startTime as string;
      if (q?.endTime) query.endTime = q.endTime as string;
    })

    const setRange = () => {
      var start = new Date(query.startTime);
      var end = new Date(query.endTime);
      monthRange.value = [{ year: start.getFullYear(), month: start.getMonth() }, { year: end.getFullYear(), month: end.getMonth() }];
    }

    watch(() => query.startTime, async (value: any) => {
      if (value) {
        setRange();
      }
    }, { immediate: true });

    watch(() => query.endTime, async (value: any) => {
      if (value) {
        setRange();
      }
    }, { immediate: true });

    return {
      query,
      grid,
      gridOptions,
      formOptions,
      itemsGrid,
      itemsGridOptions,
      insuranceGrid,
      insuranceGridOptions,
      stationIdSelectOptions,
      storeIdSelectOptions,
      productIdSelectOptions,
      queryDatepicker,
      formDatepicker,
      bookingTimePickerOptions,
      changeStation,
      selectBookingTime,
      monthRange,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    onGridEdit(row: any, callback: any) {
      if (row.Id) {
        this.changeStation(row.StationId, row, false);
      }
      callback();
    },
    async onAddItem(masterRow: any) {
      if (!masterRow.Id) {
        try {
          const order = await this.$model.dispatch("commissionRecord/insert", masterRow);
          Object.assign(masterRow, order);
        } catch (e: any) {
          this.$send('error', e);
          return;
        }
        this.grid.refresh();
      }
      this.itemsGrid.addNewRow({
        OrderId: masterRow.Id,
        Quantity: 1
      });
    },
    async onAddInsurer(masterRow: any) {
      if (!masterRow.Id) {
        try {
          const order = await this.$model.dispatch("commissionRecord/insert", masterRow);
          Object.assign(masterRow, order);
        } catch (e: any) {
          this.$send('error', e);
          return;
        }
        this.grid.refresh();
      }
      this.insuranceGrid.addNewRow({
        OrderId: masterRow.Id,
        Quantity: 1
      });
    },
    async onProductChange(row: any) {
      const product = await this.$model.dispatch("product/find", row.ProductId);
      row.Price = product.SalePrice;
      row.Amount = row.Price * row.Quantity;
    },
    setQueryTime(value: Date[]) {
      const start = new Date(value[0].getFullYear(), value[0].getMonth(), 1, 0, 0, 0, 0);
      const nextMonth = new Date(value[1].getFullYear(), value[1].getMonth() + 1, 1, 0, 0, 0, 0);
      const end = new Date(nextMonth.getTime() - 1);
      this.query.startTime = this.$utils.formatDate(start, "yyyy/MM/dd HH:mm");
      this.query.endTime = this.$utils.formatDate(end, "yyyy/MM/dd HH:mm");
      this.queryDatepicker.closeMenu();
    },
    async onGridPaid(rows: any[]) {
      const data = rows.filter(e => e.Paid === false)
      if (data.length > 0) {
        data.forEach(e => { e.Paid = true });
        await this.$model.dispatch("commissionRecord/save", { updateRows: rows });
        this.hideBatchDropDown()
        this.grid.reload()
      }
    },
    async onGridUnpaid(rows: any[]) {
      const data = rows.filter(e => e.Paid === true)
      if (data.length > 0) {
        data.forEach(e => { e.Paid = false });
        await this.$model.dispatch("commissionRecord/save", { updateRows: rows });
        this.hideBatchDropDown()
        this.grid.reload()
      }
    }
  },
});
